import './team.scss';

import { ReactComponent as Twitter } from '../../../../assets/icons/twitter.svg';
import Alex from '../../../../assets/team/alex.webp';
import Bp from '../../../../assets/team/bp.jpg';
import Kdubz from '../../../../assets/team/kdubz.png';
import Seb from '../../../../assets/team/seb.png';
import Sim from '../../../../assets/team/sim.png';

const Team = () => {
  return (
    <div className="team-container">
      <div className="member">
        <img src={Kdubz} alt="kDubz" />
        <div className="member-name">
          kDubz
          <a href="https://twitter.com/DamnDubz" target="_blank" rel="noreferrer">
            <Twitter />
          </a>
        </div>
        <div className="member-title">Founder & Dev</div>
      </div>
      <div className="member">
        <img src={Seb} alt="seb" />
        <div className="member-name">
          Seb{' '}
          <a href="https://twitter.com/Seb_Moriarty" target="_blank" rel="noreferrer">
            <Twitter />
          </a>
        </div>
        <div className="member-title">Founder & Biz Dev</div>
      </div>
      <div className="member">
        <img src={Bp} alt="bp" />
        <div className="member-name">
          BP{' '}
          <a href="https://twitter.com/bpthedev" target="_blank" rel="noreferrer">
            <Twitter />
          </a>
        </div>
        <div className="member-title">Dev</div>
      </div>
      <div className="member">
        <img src={Alex} alt="alex" />
        <div className="member-name">
          Alex{' '}
          <a href="https://twitter.com/alexx_eth" target="_blank" rel="noreferrer">
            <Twitter />
          </a>
        </div>
        <div className="member-title">UI/UX designer</div>
      </div>
      <div className="member">
        <img src={Sim} alt="sim" />
        <div className="member-name">
          Sim{' '}
          <a href="https://twitter.com/OnlySim_" target="_blank" rel="noreferrer">
            <Twitter />
          </a>
        </div>
        <div className="member-title">Community Manager</div>
      </div>
      <div className="blur" />
    </div>
  );
};

export default Team;
