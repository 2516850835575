import { FC, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './web-header-content.scss';

import Logo from 'components/logo/logo';
import Popup from 'components/popup/popup';
import useAuth from 'hooks/useAuth';
import { discordAuthUrl } from 'lib/discord-oauth2';
import { IUser } from 'types/index';

// import { ReactComponent as Close } from '../../../../assets/icons/si_Close.svg';
import { ReactComponent as CaretDown } from '../../../../assets/icons/si_Expand_more.svg';

interface WebHeaderContentProps {
  onOpenWalletSelection: any;
}

const WebHeaderContent: FC<WebHeaderContentProps> = ({
  onOpenWalletSelection,
}: WebHeaderContentProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { userProfile, isLoggedIn, removeToken } = useAuth();
  const [selectedPath, setSelectedPath] = useState('');
  const [user, setUser] = useState<IUser | undefined>();
  const [menuExpanded, setMenuExpanded] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  useEffect(() => {
    console.log('userProfile header', userProfile);
    setUser(userProfile);
  }, [userProfile, isLoggedIn]);

  useEffect(() => {
    const path = location.pathname;
    const mainPath = path.split('/')[1];
    setSelectedPath(mainPath);
  }, [location]);

  const acceptTerms = () => {
    localStorage.setItem('s33ds_acceptedTerms', 'true');
    setIsPopupOpen(false);
  };

  useEffect(() => {
    // Check if the user has already accepted the terms
    const acceptedTerms = localStorage.getItem('s33ds_acceptedTerms');
    if (user && !acceptedTerms) {
      setIsPopupOpen(true);
    }
  }, [user]);

  const goToProfile = () => {
    const win: Window = window;
    win.location = '/profile';
  };

  return (
    <div className="web-header">
      {/* <div className="header-menu">
        <nav>
          <ul className="nav-menu">
            <li
              className={clsx(selectedPath === 'projects' && 'active')}
              onClick={() => navigate('/projects')}
            >
              Explore
            </li>
            {isLoggedIn && (
              <li
                className={clsx(selectedPath === 'profile' && 'active')}
                onClick={() => navigate('/profile')}
              >
                Profile
              </li>
            )}
          </ul>
        </nav>
      </div> */}
      <Logo />
      <div className="right-menu-container">
        {/* <SeedSearchbar /> */}
        {user && (
          <>
            {/* <div className="divider-vertical"></div> */}

            <div className="account-button" onClick={() => setMenuExpanded(!menuExpanded)}>
              <img
                className="avatar"
                src={
                  user?.customAvatar ||
                  `https://cdn.discordapp.com/avatars/${user.userId}/${user.avatar}.png`
                }
              />
              <span>{user?.customUsername || user.username}</span>
              <CaretDown />
            </div>
            {menuExpanded && (
              <ul className="dropdown-menu">
                <li onClick={goToProfile}>My account</li>
                {/* <div className="divider-horizontal"></div> */}
                <li onClick={removeToken}>Log out</li>
              </ul>
            )}
          </>
        )}
        {!user && (
          <button
            className="button button-primary"
            onClick={() => (window.location.href = discordAuthUrl)}
          >
            Log in
          </button>
        )}
        {/* <WalletMultiButton /> */}
      </div>
      {isPopupOpen && (
        <Popup onClosePopup={() => setIsPopupOpen(false)} alignment="center">
          <div className="popup-container card waiver">
            <h1>S33DS User Agreement</h1>
            <p className="terms">
              S33DS is not responsible for any bad actors, wrongdoings, rug pulls, or anything of
              that nature that occurs on our presale platform. We do our best to only list quality
              projects and turn down projects we believe are not a good fit for our platform. It is
              important that you do your own due diligence before participating in sales on our
              platform. S33DS does not endorse any project affiliated with our presale platform
              unless explicitly communicated.
            </p>
            <button className="button button-primary agree-btn" onClick={acceptTerms}>
              I AGREE
            </button>
            <p className="disclaimer">
              By clicking agree you fully consent to the terms outlined above.
            </p>
          </div>
        </Popup>
      )}
    </div>
  );
};

export default WebHeaderContent;
