import './partnered-projects.scss';

import Ahoy from '../../../../assets/clients/ahoy.webp';
import BeJoe from '../../../../assets/clients/bejoe.png';
import Evos from '../../../../assets/clients/evos.png';
import FiftySeventh from '../../../../assets/clients/fiftyseventh.png';
import LiquidCapital from '../../../../assets/clients/liquid-capital.png';
import MonkeDao from '../../../../assets/clients/monkedao.webp';
import Psydelve from '../../../../assets/clients/psydelve.png';
import Raposa from '../../../../assets/clients/raposa.png';
import Udao from '../../../../assets/clients/udao.png';
import WolfCapital from '../../../../assets/clients/wolf-capital.png';

const PartneredProjects = () => {
  const logos = [
    { src: Udao, className: 'udao' },
    { src: MonkeDao, className: 'monkedao' },
    { src: FiftySeventh, className: '' },
    { src: WolfCapital, className: '' },
    { src: Raposa, className: '' },
    { src: Psydelve, className: '' },
    { src: LiquidCapital, className: '' },
    { src: BeJoe, className: '' },
    { src: Evos, className: 'evos' },
    { src: Ahoy, className: 'ahoy' },
  ];

  return (
    <div className="partnered-projects-container">
      <div className="logos">
        {Array(5)
          .fill(0)
          .map((_, index) => (
            <div key={index} className="logos-slide">
              {logos.map((logo, logoIndex) => (
                <img key={logoIndex} className={logo.className} src={logo.src} alt="" />
              ))}
            </div>
          ))}
      </div>
    </div>
  );
};

export default PartneredProjects;
