import React from 'react';

import SeedScan from '../../assets/s33ds-scan.gif';
import './LoadingScreen.scss';

interface LoadingScreenProps {
  isVisible: boolean;
}

const LoadingScreen: React.FC<LoadingScreenProps> = ({ isVisible }) => {
  return (
    <div className={`loading-screen fade-screen ${isVisible ? 'visible' : 'hidden'}`}>
      <img src={SeedScan} alt="Loading" />
    </div>
  );
};

export default LoadingScreen;
