import './platform-info.scss';

import HowItWorks from '../../../../assets/how-it-works.png';
import { ReactComponent as Brush } from '../../../../assets/icons/brush.svg';
import { ReactComponent as Graph } from '../../../../assets/icons/graph.svg';
import { ReactComponent as Task } from '../../../../assets/icons/task-square.svg';

const PlatformInfo = () => {
  return (
    <div className="platform-info-container">
      <div className="how-it-works-visual">
        <img src={HowItWorks} alt="how it works" />
        <div className="blur" />
      </div>
      <div className="how-it-works-container">
        <div className="how-it-works-item">
          <Task />
          <div className="col">
            <span className="item-title">Register your collection</span>
            <div className="item-description">
              Complete the registration form, providing essential details about your Presale. The
              team will contact you for confirmation.
            </div>
          </div>
        </div>
        <div className="how-it-works-item">
          <Brush />
          <div className="col">
            <span className="item-title">Create Your Custom Presale Page</span>
            <div className="item-description">
              Clients can tailor their presale page with unique color schemes, Twitter sharing
              options, and loading screens offering a personalized experience.
            </div>
          </div>
        </div>
        <div className="how-it-works-item">
          <Graph />
          <div className="col">
            <span className="item-title">Launch & Track Your Sales</span>
            <div className="item-description">
              Once launched, utilize the platform&apos;s tracking features to monitor sales
              performance and gain insights on your Presale market performance.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlatformInfo;
