import jwtDecode from 'jwt-decode';
import { useState, useEffect } from 'react';

import { useGetUserById } from 'api/getUserById';
import storage from 'utils/storage';

import { IToken, IUser } from '../types';

const useAuth = () => {
  const [userProfile, setUserProfile] = useState<IUser | undefined>();

  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(() => {
    const token = storage.getToken();
    return !!token;
  });

  const removeToken = () => {
    storage.clearToken();
    setIsLoggedIn(false);
  };

  const getUserId = () => {
    const token = storage.getToken();
    if (token) {
      const tokenData: { exp: number; id: string } = jwtDecode(token);
      return tokenData.id;
    } else {
      return undefined;
    }
  };

  const { data } = useGetUserById(getUserId());

  useEffect(() => {
    if (data) setUserProfile(data);
  }, [data]);

  useEffect(() => {
    if (!isLoggedIn) {
      setUserProfile(undefined);
    }
  }, [isLoggedIn]);

  return {
    isLoggedIn,
    removeToken,
    userProfile,
    setUserProfile,
    getUserId,
  };
};

export default useAuth;
