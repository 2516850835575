import { useNavigate } from 'react-router-dom';

import Raposa from '../../../assets/home-images/raposa.webp';
import SMB from '../../../assets/home-images/smb.webp';
import WolfCap from '../../../assets/home-images/wolf-cap.png';
import Faq from '../components/faq/faq';
import Metrics from '../components/metrics/metrics';
import PartneredProjects from '../components/partnered-projects/partnered-projects';
import PlatformInfo from '../components/platform-info/platform-info';
import Team from '../components/team/team';
import './Home.scss';

const Home = () => {
  const navigate = useNavigate();

  return (
    <main className="home">
      <section className="landing-screen">
        <div className="content-container">
          <div className="landing-content" data-aos="zoom-out">
            <h1>
              Bespoke <span className="mint">presale</span> services for high quality NFT
              collections
            </h1>
            <p>
              S33DS is a dynamic and constantly evolving Presale platform that is designed to create
              the best user experiences possible.{' '}
            </p>
            <div className="action-buttons">
              <button className="button button-primary" onClick={() => navigate('/projects')}>
                View collections
              </button>
              {/* <button className="button button-secondary">Apply</button> */}
            </div>
            <div className="blur" />
          </div>
          <div className="landing-visual-container">
            <img src={SMB} className="back-image" />
            <img src={WolfCap} className="middle-image" />
            <img src={Raposa} className="first-image" />
            <div className="blur" />
          </div>
          {/* {!!data && <SeedCarousel projects={data} />} */}
        </div>
      </section>
      <section className="partnered-projects">
        <div data-aos="fade-up">
          <PartneredProjects />
        </div>
      </section>
      <section className="platform-info">
        <div className="content-container" data-aos="fade-up">
          <div className="section-header">
            <div className="section-title">How it works</div>
          </div>
          {<PlatformInfo />}
        </div>
      </section>
      <section className="kpis">
        <div className="content-container" data-aos="fade-up">
          <Metrics />
        </div>
      </section>
      <a
        href="https://docs.google.com/forms/d/e/1FAIpQLSdXzf1umzCTVMY0PNYtquozd21w7VrNXGM1MBqOPRpQLYyhJg/viewform?usp=sf_link"
        target="_blank"
        rel="noreferrer"
        className="apply-button"
      >
        <button className="button button-primary">Apply Now</button>
      </a>
      <section className="team">
        <div className="content-container" data-aos="fade-up">
          <div className="section-header">
            <div className="section-title">The Team</div>
          </div>
          <Team />
        </div>
      </section>
      <section className="faq">
        <div className="content-container" data-aos="fade-up">
          <div className="section-header">
            <div className="section-title">FAQ</div>
          </div>
          <Faq />
        </div>
      </section>

      {/* <section className="featured-project">
        <div className="content-container">
          <div className="section-header" data-aos="fade-up">
            <h2>Project spotlight</h2>
            <h4>Every week we highlight a project for max exposure</h4>
          </div>
          {!!data && <FeaturedProject project={data[0]} />}
        </div>
      </section> */}

      {/* <section className="trending-projects">
        <div className="content-container">
          <TrendingProjects />
        </div>
      </section> */}
    </main>
  );
};

export default Home;
