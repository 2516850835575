import './coming-soon.scss';

import { ReactComponent as S33dsLogo } from '../../assets/logos/s33ds_logo_single.svg';

const ComingSoon = () => {
  return (
    <div className="coming-soon-container">
      <S33dsLogo />
      <span>Coming soon</span>
    </div>
  );
};

export default ComingSoon;
