import { useQuery } from '@tanstack/react-query';

import { axios } from 'lib/axios';

export const getNonce = (): Promise<string> => axios.get('/auth/nonce');

export const useGetNonce = () => {
  return useQuery({
    queryKey: ['getNonce'],
    queryFn: getNonce,
  });
};
