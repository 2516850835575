import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Spinner from 'components/spinner/spinner';
import useAuth from 'hooks/useAuth';
import { axios } from 'lib/axios';
import { addToast } from 'lib/react-toastify';
import storage from 'utils/storage';

const AuthLanding = () => {
  const navigate = useNavigate();
  const { isLoggedIn } = useAuth();

  useEffect(() => {
    if (isLoggedIn) {
      navigate('/');
      return;
    }
    const params = new URLSearchParams(window.location.search);
    const code = params.get('code');
    if (!code) {
      navigate('/');
      return;
    }
    try {
      axios.post('auth/login', { code: code }).then((res: any) => {
        storage.setToken(res.accessToken, res.refreshToken);
        window.history.go(-2);
      });
    } catch (error) {
      navigate('/');
      addToast('error', 'Something went wrong, please try again');
    }
  }, []);

  return (
    <div className="spinner-container margin-header">
      <Spinner size="lg" />
    </div>
  );
};

export default AuthLanding;
