import Axios, { AxiosRequestConfig } from 'axios';

import { API_URL } from 'config';
import storage from 'utils/storage';

import { addToast } from './react-toastify';

// function authRequestInterceptor(config: AxiosRequestConfig) {
//   const token = storage.getToken();

//   if (token) {
//     config.headers = {
//       ...config.headers,
//       Authorization: `Bearer ${token}`,
//     };
//   }
//   return config;
// }

export const axios = Axios.create({
  baseURL: API_URL,
  withCredentials: true,
});

axios.interceptors.request.use(
  async (config) => {
    config.headers = {
      Accept: 'application/json',
    };

    const token = storage.getToken();
    if (token) {
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// axios.interceptors.request.use(authRequestInterceptor);
axios.interceptors.response.use(
  (response) => {
    return response.data;
  },
  async (error) => {
    const originalConfig = error.config;
    if (error.response) {
      //
      // Unauthorized request
      //
      if (error.response.status == 401 && !originalConfig._retry) {
        originalConfig._retry = true;

        try {
          const rs = await getRefreshToken();
          const { accessToken, refreshToken } = rs.data;
          storage.setToken(accessToken, refreshToken);
          axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
          return axios(originalConfig);
        } catch (error) {
          console.log('error', error);
        }
      } else if (error.response.status == 401 && originalConfig._retry) {
        storage.clearToken();
        window.location.href = `${window.location.origin}/`;
        addToast('error', 'Unauthorized');
        return Promise.reject(error);
      }
    }

    // storage.clearToken();
    // window.location.href = `${window.location.origin}/`;
    return Promise.reject(error);
  }
);

async function getRefreshToken() {
  return Axios.post(`${API_URL}/auth/refresh_token`, {
    refreshToken: storage.getRefreshToken(),
  });
}
