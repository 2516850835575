import { MinusIcon, PlusIcon } from '@heroicons/react/24/solid';
import clsx from 'clsx';
import { useState } from 'react';
import './faq.scss';

const Faq = () => {
  const [selectedQuestion, setSelectedQuestion] = useState<number | undefined>();

  const selectQuestion = (num: number) => {
    if (selectedQuestion === num) {
      setSelectedQuestion(undefined);
    } else {
      setSelectedQuestion(num);
    }
  };

  return (
    <div className="faq-container">
      <div className={clsx('item what-is', selectedQuestion === 1 && 'expanded')}>
        <div className="header" onClick={() => selectQuestion(1)}>
          <div className="title">What are the fees?</div>
          {selectedQuestion === 1 ? <MinusIcon /> : <PlusIcon />}
        </div>
        <p className="item-content">
          We have a number of packages that users can choose to fit their budget and complexity of
          Presale.
        </p>
      </div>
      <div className={clsx('item multi', selectedQuestion === 2 && 'expanded')}>
        <div className="header" onClick={() => selectQuestion(2)}>
          <div className="title">Where do I buy?</div>
          {selectedQuestion === 2 ? <MinusIcon /> : <PlusIcon />}
        </div>
        <p className="item-content">
          Presales will happen on a dedicated project group that will display all roles/DAO’s that
          are participating. Clients can also create custom links which will also fall under their
          group.
        </p>
      </div>
      <div className={clsx('item', selectedQuestion === 3 && 'expanded')}>
        <div className="header" onClick={() => selectQuestion(3)}>
          <div className="title">Who can I contact for support?</div>
          {selectedQuestion === 3 ? <MinusIcon /> : <PlusIcon />}
        </div>
        <p className="item-content">
          You can open a support ticket in our server and we’ll be able to assist you.
        </p>
      </div>
      <div className={clsx('item', selectedQuestion === 4 && 'expanded')}>
        <div className="header" onClick={() => selectQuestion(4)}>
          <div className="title">What Chains do you support?</div>
          {selectedQuestion === 4 ? <MinusIcon /> : <PlusIcon />}
        </div>
        <p className="item-content">We support Solana and Polygon. Ethereum is in the works.</p>
      </div>
      <div className={clsx('item', selectedQuestion === 5 && 'expanded')}>
        <div className="header" onClick={() => selectQuestion(5)}>
          <div className="title">Am I going to make it?</div>
          {selectedQuestion === 5 ? <MinusIcon /> : <PlusIcon />}
        </div>
        <p className="item-content">We got you!</p>
      </div>
    </div>
  );
};

export default Faq;
