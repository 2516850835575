const storagePrefix = process.env.REACT_APP_STORAGE_PREFIX as string;

const storage = {
  getToken: () => {
    return localStorage.getItem(`${storagePrefix}_access`);
  },
  getRefreshToken: () => {
    return localStorage.getItem(`${storagePrefix}_refresh`);
  },
  setToken: (accessToken: string, refreshToken: string) => {
    localStorage.setItem(`${storagePrefix}_access`, accessToken);
    localStorage.setItem(`${storagePrefix}_refresh`, refreshToken);
  },
  clearToken: () => {
    window.localStorage.removeItem(`${storagePrefix}_access`);
    window.localStorage.removeItem(`${storagePrefix}_refresh`);
  },
};

export default storage;
