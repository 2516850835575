import React, { createContext, FC, useEffect, useState } from 'react';

export const themeContext = createContext<any>(undefined);

const getInitialTheme = () => {
  if (typeof window !== 'undefined' && window.localStorage) {
    const storedPrefs = window.localStorage.getItem('seed-theme');

    if (typeof storedPrefs === 'string') {
      return storedPrefs;
    }
  }

  return 'dark';
};

type ThemeProviderProps = React.PropsWithChildren<{
  children?: React.ReactNode;
}>;

const ThemeProvider: FC<ThemeProviderProps> = ({ children }: ThemeProviderProps) => {
  const [theme, setTheme] = useState<string>()!;

  useEffect(() => {
    if (!theme) {
      setTheme(getInitialTheme());
    }

    if (location.pathname === '/profile') {
      setTheme('dark');
    }

    if (theme) {
      const root = document.documentElement;
      root.setAttribute('theme', theme);
      window.localStorage.setItem('seed-theme', theme);
    }
  }, [theme, location]);

  return <themeContext.Provider value={[theme, setTheme]}>{children}</themeContext.Provider>;
};

export default ThemeProvider;
