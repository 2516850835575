import clsx from 'clsx';
import { useEffect, useState } from 'react';

import './header.scss';

import Popup from 'components/popup/popup';
import WalletConnections from 'components/wallet-connections/wallet-connections';
import useAuthContext from 'hooks/useAuthContext';

import { ReactComponent as Close } from '../../assets/icons/si_Close.svg';

import WebHeaderContent from './components/web-header-content/web-header-content';

const Header = () => {
  const { isLoggedIn } = useAuthContext();
  const [isFlyoutOpen, setIsFlyoutOpen] = useState(false);

  useEffect(() => {}, [isLoggedIn]);

  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener('scroll', () => {
      setScroll(window.scrollY > 10);
    });
  }, []);

  return (
    <>
      <header className={clsx('app-header', scroll && 'scrolled')}>
        <div className="header-content">
          {/* <MobileHeaderContent onOpenWalletSelection={setIsFlyoutOpen} /> */}
          <WebHeaderContent onOpenWalletSelection={setIsFlyoutOpen} />
        </div>
      </header>
      {isFlyoutOpen && (
        <Popup onClosePopup={() => setIsFlyoutOpen(false)} alignment="right">
          <div className={clsx('wallets-container', isFlyoutOpen && 'open')}>
            <div className="wallets-container-header">
              <h2 className="wallets-title">Connections</h2>
              <Close onClick={() => setIsFlyoutOpen(false)} />
            </div>
            <WalletConnections />
          </div>
        </Popup>
      )}
    </>
  );
};

export default Header;
