import { useQuery } from '@tanstack/react-query';

import { axios } from 'lib/axios';
import { IUser } from 'types/index';
import storage from 'utils/storage';

export const getUserById = (id: string | undefined): Promise<IUser | void> => {
  if (!id || id === '') {
    return Promise.resolve();
  }
  return axios.get(`users/me`);
};

export const useGetUserById = (id: string | undefined) => {
  return useQuery({
    queryKey: ['getUserById', id],
    queryFn: () =>
      getUserById(id).catch((err) => {
        storage.clearToken();
        return null;
      }),
    enabled: !!id,
  });
};
