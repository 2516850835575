import { lazy } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import AuthLanding from 'components/auth/authLanding';
import TwitterAuthLanding from 'components/auth/twitterAuthLanding';
import ComingSoon from 'components/coming-soon/coming-soon';
import HomeRoutes from 'features/home/routes';
import Sandbox from 'features/sandbox/sandbox';

// eslint-disable-next-line no-restricted-imports
// import Projects from 'features/projects/routes/Projects';
// Projects

const ProjectsRoutes = lazy(() => import('features/projects/routes'));
const ProfileRoutes = lazy(() => import('features/profile/routes'));
// ProjectsRoutes

export const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/">
        <Route index element={<HomeRoutes />} />
        <Route index element={<ComingSoon />} />
        <Route path="projects/*" element={<ProjectsRoutes />} />
        <Route path="profile/*" element={<ProfileRoutes />} />
        <Route path="auth" element={<AuthLanding />} />
        <Route path="auth/twitter" element={<TwitterAuthLanding />} />
        {/* <Route path="sandbox" element={<Sandbox />} /> */}
        <Route path="*" element={<Navigate to="/" />} />
      </Route>
    </Routes>
  );
};

export default AppRoutes;
