import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export function addToast(
  type: 'info' | 'success' | 'warning' | 'error' | 'default',
  message: string
) {
  toast(message, {
    type: type,
    theme: 'colored',
  });
}
