import { QueryClientProvider } from '@tanstack/react-query';
import { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { queryClient } from 'lib/react-query';

import AuthProvider from './AuthProvider';
// import { EthereumWalletProvider } from './EthereumWalletProvider';
import { SolanaWalletProvider } from './SolanaWalletProvider';
import ThemeProvider from './ThemeProvider';

const ErrorFallback = () => {
  return (
    <div className="error-screen" role="alert">
      It seems something went wrong. <br /> Please try again.
    </div>
  );
};

type AppProviderProps = {
  children: React.ReactNode;
};

export const AppProvider = ({ children }: AppProviderProps) => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <AuthProvider>
            <SolanaWalletProvider>
              {/* <EthereumWalletProvider> */}
              <ThemeProvider>
                <ToastContainer />
                <Suspense fallback={<div className="fallback-spinner">Loading</div>}>
                  {children}
                </Suspense>
              </ThemeProvider>
              {/* </EthereumWalletProvider> */}
            </SolanaWalletProvider>
          </AuthProvider>
        </QueryClientProvider>
      </BrowserRouter>
    </ErrorBoundary>
  );
};
