import clsx from 'clsx';
import { FC, useEffect, useState } from 'react';

import OutkastSpinner from 'assets/custom-spinners/s33ds_outkast_spinner.gif';
import SeedScan from 'assets/s33ds-scan.gif';

import BankmenSpinner from '../../assets/bankmen.gif';
import MaidenSpinner from '../../assets/MaidensLoader.gif';
import UDAOSpinner from '../../assets/udao.gif';
// import PlantGrowingGif from '../../assets/seeds-loader.gif';

import './spinner.scss';

const sizes = {
  sm: 40,
  md: 60,
  lg: 80,
  xl: 100,
};

interface SpinnerProps {
  size?: keyof typeof sizes;
  className?: string;
}

const Spinner: FC<SpinnerProps> = ({ size = 'lg', className = '' }: SpinnerProps) => {
  const [theme, setTheme] = useState('s33ds');

  useEffect(() => {
    const root = document.documentElement;
    const theme = root.getAttribute('theme');

    if (theme === 'maidens') {
      setTheme('maidens');
    }

    if (theme === 'bankmen') {
      setTheme('bankmen');
    }

    if (theme === 'udao') {
      setTheme('udao');
    }
  }, []);

  return (
    <div className={clsx('spinner-component-container', className)}>
      {/* <img src={theme === 'monkedao' ? MonkedaoSpinner : S33DGhost} alt="loader" /> */}
      <img src={SeedScan} alt="loader" />
      {/* <img src={OutkastSpinner} alt="loader" /> */}
      <span>Loading...</span>
      {/* <DotLoader size={sizes[size]} color="#5FB78C" /> */}
    </div>
  );
};

export default Spinner;
