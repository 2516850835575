import './metrics.scss';

const Metrics = () => {
  return (
    <div className="metrics-container">
      <div className="metric-container">
        <h2>23</h2>
        <p>Projects Serviced</p>
      </div>
      <div className="metric-container">
        <div className="sol-value">
          {/* <Sol /> */}
          <div className="sol-icon">◎</div>
          <h2>91K</h2>
        </div>
        <p>Solana Volume</p>
      </div>
      <div className="metric-container">
        <h2>$1.94M</h2>
        <p>Dollars Raised</p>
      </div>
      <div className="metric-container">
        <h2>29,524</h2>
        <p>NFTs Purchased</p>
      </div>
    </div>
  );
};

export default Metrics;
