import { useContext } from 'react';

import { AuthContext } from '../providers/AuthProvider';
import { IAuth } from '../types';

const useAuthContext = (): IAuth => {
  return useContext(AuthContext)!;
};

export default useAuthContext;
