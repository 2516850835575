import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import bs58 from 'bs58';
import { useEffect } from 'react';

import { useGetNonce } from 'api/getNonce';
import useAuth from 'hooks/useAuth';
import { axios } from 'lib/axios';
import { addToast } from 'lib/react-toastify';

interface ISolanaWalletConnectButton {
  connectedWallet: string | null;
}

const SolanaWalletConnectButton = ({ connectedWallet }: ISolanaWalletConnectButton) => {
  const { publicKey, disconnect, signMessage } = useWallet();
  const { connection } = useConnection();
  const getNonce = useGetNonce();
  const { getUserId } = useAuth();

  useEffect(() => {
    if (publicKey && connectedWallet && publicKey.toString() !== connectedWallet) {
      disconnect();
      addToast('error', 'Please connect linked wallet');
      return;
    }
    if (connection && publicKey) {
      getSignature();
    }
  }, [publicKey]);

  const getSignature = async () => {
    if (publicKey && signMessage) {
      let signature = '';
      try {
        const nonce = getNonce.data;

        const encodedMessage = new TextEncoder().encode(`S33DS: ${nonce}`);
        const sig = await signMessage(encodedMessage);
        signature = bs58.encode(sig);

        axios
          .post(`users/${getUserId()}/link-solana-wallet`, {
            nonce: nonce,
            publicKey: publicKey.toString(),
            signature: signature,
          })
          .then((res: any) => {
            console.log('res', res);
          })
          .catch((err) => {
            if (err?.response?.data && err.response.data.statusCode === 409) {
              addToast('error', 'Solana address already connected to another account');
            }
          });
      } catch (error: any) {
        disconnect();
        addToast('error', error.message);
      }

      // Add wallet

      // await login({
      //   wallet: publicKey.toString(),
      //   signature,
      // });
    } else {
      addToast('error', 'Signing with this wallet is not supported yet');
    }
  };

  return <WalletMultiButton />;
};

export default SolanaWalletConnectButton;
