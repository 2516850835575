import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Spinner from 'components/spinner/spinner';
import useAuth from 'hooks/useAuth';
import { axios } from 'lib/axios';
import { addToast } from 'lib/react-toastify';

const TwitterAuthLanding = () => {
  const navigate = useNavigate();
  const { getUserId } = useAuth();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const code = params.get('code');
    const userId = getUserId();
    if (!code || !userId) {
      navigate('/');
      return;
    }
    try {
      axios
        .post(`users/${userId}/add-twitter`, { code: code })
        .then((res: any) => {
          console.log('res', res);
          navigate('/');
        })
        .catch((err) => {
          if (err?.response?.data && err.response.data.statusCode === 409) {
            addToast('error', 'Twitter account already connected to another account');
            navigate('/');
          }
        });
    } catch (error) {
      navigate('/');
      addToast('error', 'Something went wrong, please try again');
    }
  }, []);

  return (
    <div className="spinner-container margin-header">
      <Spinner size="lg" />
    </div>
  );
};

export default TwitterAuthLanding;
