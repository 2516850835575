import { useState } from 'react';
import './footer.scss';

import Logo from 'components/logo/logo';
import Popup from 'components/popup/popup';

import { ReactComponent as Discord } from '../../assets/icons/discord.svg';
import { ReactComponent as Close } from '../../assets/icons/si_Close.svg';
import { ReactComponent as Twitter } from '../../assets/icons/twitter.svg';

const Footer = () => {
  const [termsOfserviceOpen, setTermsOfserviceOpen] = useState(false);

  return (
    <>
      <div className="footer-container">
        {/* <div className="divider-horizontal" /> */}
        <div className="footer-content">
          <Logo />
          <div className="footer-text">©2023 S33DS. All Rights Reserved</div>
          <div className="actions">
            <div
              className="footer-menu-item"
              onClick={() => setTermsOfserviceOpen(!termsOfserviceOpen)}
            >
              Terms of Service
            </div>
            <a href="https://twitter.com/S33DStart" target="_blank" rel="noreferrer">
              <Twitter />
            </a>
            <a href="https://discord.gg/S33DS" target="_blank" rel="noreferrer">
              <Discord />
            </a>
          </div>
        </div>
      </div>
      {termsOfserviceOpen && (
        <Popup alignment="center">
          <div className="popup-container footer-popup-container card">
            <>
              <div className="popup-header">
                <Close onClick={() => setTermsOfserviceOpen(false)} />
              </div>
              <div className="popup-content">
                <h2>Terms of Service</h2>
                <p className="sub-header">Effective 29th January 2023</p>
                <div className="text">
                  These Terms of Service (“Terms”) are a contract between you and S33DS Inc. (“the
                  Company”). They govern your use of the S33DS application (the “App”) and any
                  associated or connected, and other services, features, content, or applications we
                  offer through the App (collectively, the “Services”), which facilitates
                  interaction with certain decentralized cryptographic protocols to allow acceptance
                  of payments on the decentralized blockchains on which the payment transactions are
                  recorded (“Blockchain”). <br />
                  <br />
                  By using S33DS, you agree to these Terms. If you don’t agree to any of the Terms,
                  you can’t use S33DS. ‍ S33DS reserves the right, at its sole discretion, to modify
                  or replace the Terms of Service at any time. The most current version of these
                  Terms will be shared on our Site. You shall be responsible for reviewing and
                  becoming familiar with any such modifications. If a revision to the Terms, in our
                  sole discretion, is material, we will notify you of that revision by contacting
                  you through the email address associated with your account. Use of the Services by
                  you after any modification to the Terms constitutes your acceptance of the Terms
                  of Services as modified.
                  <br />
                  <br /> IMPORTANT NOTICE: S33DS WILL NOT AND CANNOT GUARANTEE THE FULFILLMENT OF
                  ANY CLAIMS OR PROMISES MADE BY THE MERCHANT UNDER A PRESALE ARRANGEMENT. THIS
                  INCLUDES BUT IS NOT LIMITED TO RECEIVING THE PRE-SOLD NFT FROM THE MERCHANT, THE
                  MERCHANT CONTINUING TO SUPPORT THE NFTS OR THE MERCHANT NOT “RUG-PULLING”. PLEASE
                  REVIEW PURCHASE TERMS BELOW.
                  <h4>Risks</h4>
                  Before using S33DS’s services, creating a Solana wallet, or purchasing digital
                  assets, you must consider whether using those Services is suitable for you
                  considering your financial circumstances. The value of digital assets like
                  cryptocurrency fluctuates and may become worthless. Cryptocurrency is not legal
                  tender. A digital asset’s past value or performance is not an indicator of its
                  future value or performance. You agree that S33DS is not responsible for any loss
                  of a digital asset, or any losses arising from theft, loss, or mishandling of any
                  digital asset, including but not limited to Solana, outside of S33DS’s sole
                  control. There is no assurance that a person that accepts cryptocurrency as
                  payment today will continue to do so in the future. ‍ Additionally,
                  cryptocurrencies are not protected by the Financial Services Compensation Scheme
                  (FSCS), the Federal Deposit Insurance Corporation (FDIC), the Securities Investor
                  Protection Corporation (SIPC) or any other public or private insurer, including
                  against cyber theft or theft by any other means. The nature of cryptocurrency may
                  lead to an increased risk of fraud or cyber-attack and your cryptocurrency value
                  may be irretrievably lost or stolen. Transfers or transactions in cryptocurrency
                  are irrevocable and irreversible. Cryptocurrency assets are not regulated in many
                  jurisdictions. <br />
                  <br /> S33DS is not an investment service and does not provide investment advice.
                  No information on the site, including but not limited to information provided by
                  S33DS, should be considered a substitute for professional investment advice. You
                  agree that you may not use the contents of S33DS’s site or services to make
                  investment decisions. S33DS is not a digital asset market and does not make any
                  representations or warranties as to the value (real or perceived) of digital
                  assets of any type, including but not limited to Solana. S33DS may provide
                  information regarding the price of digital assets. It does not make any
                  representations or warranties regarding the truth, usefulness, suitability,
                  quality, accuracy, or completeness of that data. You should not rely on such
                  pricing data provided by S33DS for any reason. You must independently verify any
                  such information from a reputable source before relying on pricing information.
                  Digital asset values are often volatile and may change instantaneously. S33DS is
                  not responsible or liable for any losses incurred by using or transferring digital
                  assets in connection with or based on the information you have received through
                  our Services.
                  <h4>About the Services</h4>
                  Our Services facilitate interactions with Blockchains to allow businesses and
                  individuals to accept payments for presale in USDC, SOL, SPL tokens and other
                  Digital Assets.
                  <h4>Purchase Terms</h4>
                  Although the payment is facilitated by our services, all presale terms and
                  fulfillment are determined by the Buyer and the Presale Entity “Merchant”.
                  <br />
                  <br /> The Company is not a party to any Purchase Terms, which are solely between
                  the Buyer and the Merchant and is not responsible for ensuring the compliance with
                  such terms or mediating or resolving any disputes with respect to such Purchase
                  Terms, including but not limited to any disputes arising out of or related to any
                  intellectual property rights associated with digital content.
                  <br />
                  <br /> The Buyer and Merchant are entirely responsible for communicating,
                  promulgating, agreeing to, and enforcing Purchase Terms, and for resolving any
                  disputes arising from any breach of any Purchase Terms.
                  <h4>Cost and Fees</h4>
                  Transactions on S33DS may be subject to fees that the Company collects to support
                  the Services.
                  <h4>Eligibility</h4>
                  You may use the Services only if you are 18 years or older and capable of forming
                  a binding contract with S33DS., and not otherwise barred from using the Services
                  under applicable law. By using the Services, you affirm that you are over 18.
                  <h4>Incorporated Rules and Policies</h4>
                  By using the Services, you agree to let S33DS collect and use information as
                  detailed in our privacy policy located here. By using S33DS, you agree to follow
                  these Rules and Policies.
                  <h4>Miscellaneous Disclaimer of Warranty</h4>
                  S33DS provides the Services to you as is. You use them at your own risk and
                  discretion. That means they don’t come with any warranty. None expressed, none
                  implied. If we don’t meet the quality level described in this warranty, you agree
                  to tell us, and we’ll work with you to try to resolve the issue. ‍
                  <h4>Limitation of Liability</h4>
                  S33DS won’t be liable to you for any damages that arise from your using the
                  Services or any connected or associated services. This includes if the Services or
                  any connected or associated services are hacked or unavailable. This includes all
                  types of damages (indirect, incidental, consequential, special, or exemplary). And
                  it includes all kinds of legal claims, such as breach of contract, breach of
                  warranty, tort, or any other loss.
                  <h4>Waiver of Rights</h4>
                  S33DS failure to enforce any right or provision of these Terms will not be
                  considered a waiver of such right or provision. The waiver of any such right or
                  provision will be effective only if in writing and signed by a duly authorized
                  representative of S33DS. Except as expressly outlined in these Terms, the exercise
                  by either party of any of its remedies under these Terms will be without prejudice
                  to its other remedies under these Terms or otherwise.
                  <h4>Severability</h4>
                  If any provision of these terms is found invalid by a court of competent
                  jurisdiction, you agree that the court should try to give effect to the party’s
                  intentions as reflected in the provision and that other provisions of the Terms
                  will remain in full effect.
                  <h4>Choice of Law and Jurisdiction</h4>
                  These Terms are governed by the Saint Vincent and Grenadines. You agree that any
                  suit arising from the Services must take place in a court located in Saint Vincent
                  and Grenadines. ‍ Entire Agreement These Terms are the whole agreement between
                  S33DS and you concerning the Services.
                  <h4>Taxes</h4>
                  You are responsible for any applicable national, state, local, sales, or use
                  taxes, value-added taxes, or similar taxes or fees payable in connection with your
                  use of the Services. If you do not pay such taxes, you will be responsible for the
                  tax liability if the transaction is later determined to be taxable, and S33DS
                  reserves the right to collect taxes or other fees from you at any time.
                  <h4>Contact Information</h4>
                  Please raise a ticket per:{' '}
                  <a href="https://discord.gg/wK46t6xC" target="_blank" rel="noreferrer">
                    https://discord.gg/wK46t6xC
                  </a>
                </div>
              </div>
            </>
          </div>
        </Popup>
      )}
    </>
  );
};

export default Footer;
