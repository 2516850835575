import React, { useState } from 'react';

import useAuth from 'hooks/useAuth';

import { IAuth } from '../types';

export const AuthContext = React.createContext<IAuth | undefined>(undefined);

type AuthProviderProps = React.PropsWithChildren<{
  children?: React.ReactNode;
}>;

const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const auth = useAuth();

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
