// import crypto from 'crypto';

// import DiscordOauth2 from 'discord-oauth2';

// export const discordClient = new DiscordOauth2({
//   clientId: process.env.REACT_APP_DISCORD_CLIENT_ID as string,
//   clientSecret: process.env.REACT_APP_DISCORD_CLIENT_SECRET as string,
//   redirectUri: `${window.location.origin}/auth`,
// });

// export const discordAuthUrl = discordClient.generateAuthUrl({
//   scope: ['identify', 'guilds', 'guilds.members.read'],
//   state: crypto.randomBytes(16).toString('hex'), // Be aware that randomBytes is sync if no callback is provided
// });

export const discordAuthUrl = process.env.REACT_APP_DISCORD_AUTH_URL as string;
