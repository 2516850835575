import AOS from 'aos';
import { Suspense, useEffect, useState } from 'react';

import Footer from 'components/footer/footer';
import Header from 'components/header/header';
import LoadingScreen from 'components/loadingScreen/LoadingScreen';
import Spinner from 'components/spinner/spinner';

import AppRoutes from './routes';

import 'aos/dist/aos.css';

function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    AOS.init();
  });

  useEffect(() => {
    // Lock the scroll when loading screen is visible
    if (isLoading) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto'; // or 'visible', depending on your preference
    }

    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 3000);

    return () => {
      clearTimeout(timer);
      // Ensure scroll is restored when component is unmounted
      document.body.style.overflow = 'auto';
    };
  }, [isLoading]);

  return (
    <div className="app">
      <LoadingScreen isVisible={isLoading} />
      <Header />
      <Suspense
        fallback={
          <main className="loading-main">
            <Spinner size="lg" />
          </main>
        }
      >
        <AppRoutes />
      </Suspense>
      <Footer />
    </div>
  );
}

export default App;
