import clsx from 'clsx';
import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import './popup.scss';

interface PopupProps {
  alignment?: 'left' | 'center' | 'right';
  onClosePopup?: any;
  children: any;
}

const Popup: React.FC<PopupProps> = ({
  alignment = 'center',
  onClosePopup,
  children,
}: PopupProps) => {
  // useEffect(() => {
  //   document.getElementsByTagName('body')[0].style.overflow = 'hidden';

  //   return function cleanup() {
  //     document.getElementsByTagName('body')[0].style.overflow = 'auto';
  //   };
  // });

  return (
    <div className={clsx('overlay-container', alignment)}>
      <div className="overlay"></div>
      {/* <div className="popup-container"></div> */}
      {children}
    </div>
  );
};

export default Popup;
