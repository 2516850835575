import { useQuery } from '@tanstack/react-query';

import { axios } from 'lib/axios';

interface IUserConnections {
  twitter: {
    name: string;
    avatar: string;
  } | null;
  solanaWallet: string | null;
  metamaskWallet: string | null;
}

export const getUserConnections = (id: string | undefined): Promise<IUserConnections | void> => {
  if (!id || id === '') {
    return Promise.resolve();
  }
  return axios.get(`users/connections`);
};

export const useGetUserConnections = (id: string | undefined) => {
  return useQuery({
    queryKey: ['getUserConnections', id],
    queryFn: () =>
      getUserConnections(id).catch((err) => {
        return null;
      }),
    enabled: !!id,
  });
};
