const Logo = () => {
  const goToHome = () => {
    const win: Window = window;
    win.location = '/';
  };

  return (
    <div id="logo" className="logo" onClick={goToHome}>
      <img src="/assets/logos/s33ds_symbol.svg" className="s33ds" />
      <div className="logo-text">S33DS</div>
    </div>
  );
};

export default Logo;
