import { useEffect, useState } from 'react';
// import { useAccount, useConnect, useDisconnect } from 'wagmi';
// import { InjectedConnector } from 'wagmi/connectors/injected';

import './wallet-connections.scss';
import { useGetUserConnections } from 'api/getUserConnections';
import Spinner from 'components/spinner/spinner';
import useAuth from 'hooks/useAuth';
// import { authUrl } from 'lib/twitter-api-sdk';

import { ReactComponent as Ethereum } from '../../assets/icons/ethereum.svg';
import { ReactComponent as Solana } from '../../assets/icons/solana.svg';
import { ReactComponent as Twitter } from '../../assets/icons/twitter.svg';

import SolanaWalletConnectButton from './solana-wallet-connect-button';

const WalletConnections = () => {
  // const { address, connector, isConnected } = useAccount();
  const { getUserId } = useAuth();
  const { data: connections, isFetching } = useGetUserConnections(getUserId());
  // const { connect } = useConnect({
  //   connector: new InjectedConnector(),
  // });
  // const { disconnect } = useDisconnect();

  useEffect(() => {
    console.log('connections', connections);
  }, [connections]);

  return (
    <div className="wallet-connections-container">
      <div className="wallet-container">
        <div className="wallet-title-container">
          <div className="wallet-logo">
            <Twitter />
          </div>
          <div className="wallet-title">Twitter</div>
        </div>
        <div className="connection-container">
          {isFetching && <Spinner size="sm" />}
          {!isFetching && !!connections && !!connections.twitter && (
            <>
              <img className="twitter-avatar" src={connections.twitter.avatar} />
              <div className="twitter-title">{connections.twitter.name}</div>
            </>
          )}
        </div>
        {/* {!isFetching && (!connections || !connections.twitter) && (
          <a href={authUrl} className="button button-secondary">
            Connect
          </a>
        )} */}
      </div>
      <div className="wallet-container">
        <div className="wallet-title-container">
          <div className="wallet-logo">
            <Solana />
          </div>
          <div className="wallet-title">Solana</div>
        </div>
        <div className="connection-container">
          {isFetching && <Spinner size="sm" />}
          {!isFetching && !!connections && !!connections.solanaWallet && connections.solanaWallet}
        </div>
        {!isFetching && (!connections || !connections.solanaWallet) && (
          <SolanaWalletConnectButton connectedWallet={null} />
        )}
      </div>
      {/* <div className="wallet-container">
        <div className="wallet-title-container">
          <div className="wallet-logo">
            <Ethereum />
          </div>
          <div className="wallet-title">Ethereum</div>
        </div>
        {isConnected && (
          <div>
            Connected to {address}
            <button className="button button-secondary" onClick={() => disconnect()}>
              Disconnect
            </button>
          </div>
        )}
        {!isConnected && (
          <button className="button button-secondary" onClick={() => connect()}>
            Connect Wallet
          </button>
        )}
      </div> */}
    </div>
  );
};

export default WalletConnections;
